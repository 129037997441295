import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import { ROUTES } from "../../lib/consts";
import "./Completed.scss";
import tickimg from "../../assets/images/tickimg.png";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import API from "../../api";
import pack from "../../assets/images/pack.png";
import download from "../../assets/images/download.png";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Download = (props: any) => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [voucherDetailText, setVoucherDetailText] = useState("");
  const [url, setUrl] = useState("");
  const { text, setText } = props;
  const isCashbackWinner = useSelector(
    (state: RootState) => state.authReducer.isCashbackWinner
  );

  const imageUrl = localStorage.getItem("image-url") || "";

  const toDataURL = (url: string) => {
    return fetch(url)
      .then((response) => {
        console.log("Fetch Response:", response);
        return response.blob();
      })
      .then((blob) => {
        console.log("Blob:", blob);
        return URL.createObjectURL(blob);
      });
  };

  const downloadImage = async (url: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleClick = () => {
    API.action("download")
      .then(() => {
        downloadImage(imageUrl);
        // navigate(ROUTES.DESIGN_THANKYOU);
        // toast.error("API success");
      })
      .catch((err: any) => {
        toast.error(JSON.stringify(err));
        toast.error("API error");
      });
  };
  const rewardClick = () => {
    navigate(ROUTES.DESIGN_PAYMENT);
  };

  // useEffect(() => {
  //   API.saveNameDetails(text, url)
  //     .then((res) => {
  //       console.log("res:", res);
  //       const imageUrl = res.url;
  //       setUrl(imageUrl);
  //     })
  //     .catch((err: any) => {
  //       console.log("err :", err);
  //     });
  // }, [text, url]);

  return (
    <MainLayout className="completed-page">
      <div className="download-text">
        <p>
          Download Your Created Design <br />
          Here
        </p>
      </div>
      <img className="pack1" src={imageUrl} alt="pack" />
      <div className="btn-container1">
        <button className="open-text1" onClick={handleClick}>
          DOWNLOAD <img src={download} alt="." />
        </button>
        {isCashbackWinner ? (
          <>
            <p className="disclaimer">
              *Download your creative design before claiming your reward
            </p>
            <p className="claim-reward" onClick={rewardClick}>
              Claim your reward
            </p>
          </>
        ) : (
          <>
            <p className="claim-text1">
              Participate more to stand <br />a chance to win attractive <br />
              prizes!
            </p>
            <p className="claim-text2">
              *Each mobile number is eligible for assured <br />
              cashback for once only
            </p>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Download;
