import MainLayout from "../../layouts/MainLayout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import { useEffect, useRef, useState } from "react";
import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, ROUTES } from "../../lib/consts";
import store from "../../store/store";
import {
  isRegistered,
  setAccessToken,
  canClaim,
} from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import PaymentPage from "../Design/PaymentPage/PaymentPage";
import CashbackPayout from "../CashbackPayout/CashbackPayout";
import { useAuthentication } from "../../hooks/useAuthentication";
import { addCaptchaScript, hideCaptchaBadge } from "../../lib/utils";
import { setUserIdentification } from "../../store/actions/authAction";
import { log } from "console";
import arrow from "../../assets/images/arrow.png";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";

const Register = () => {
  const [enteredMobile, setEnteredMobile] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  const timer = useRef<any | null>(null);

  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
    setCheckboxError("");
  };

  useEffect(() => {
    if (isLoggedIn) {
      const handleLocationChange = () => {
        navigate(ROUTES.PAYOUT_FORM); // Change this to the appropriate route
      };

      window.addEventListener("popstate", handleLocationChange);

      return () => {
        window.removeEventListener("popstate", handleLocationChange);
      };
    }
  }, [navigate, isLoggedIn]);

  const RegisterValidation = Yup.object().shape({
    mobile: Yup.string()
      .required(
        t("registerPage.errors.invalidMobile") ||
          "*Please enter a valid 10-digit number"
      )
      .matches(
        /^[56789][0-9]{9}$/,
        t("registerPage.errors.invalidMobile") ||
          "*Please enter a valid 10-digit number"
      ),
    smocker: Yup.boolean()
      .required("The campaign is only available for smokers.")
      .oneOf([true], "The campaign is only available for smokers."),
    aboveLegalDrinkingAge: Yup.boolean()
      .required(" Please accept terms and conditions to proceed")
      .oneOf([true], " Please accept terms and conditions to proceed"),
  });

  const OtpValidation = Yup.object().shape({
    otp: Yup.string()
      .required(
        t("registerPage.errors.invalidOtp") || "*Please enter valid OTP"
      )
      .matches(
        /^[0-9]{6}$/,
        t("registerPage.errors.incorrectOtp") ||
          "*You have entered an incorrect OTP. Please try again"
      ),
    smocker: Yup.boolean()
      .required("The campaign is only available for smokers.")
      .oneOf([true], "The campaign is only available for smokers."),
    aboveLegalDrinkingAge: Yup.boolean()
      .required(" Please accept terms and conditions to proceed")
      .oneOf([true], " Please accept terms and conditions to proceed"),
  });

  const startTimer = () => {
    if (timer) clearInterval(timer.current);
    let time = 60;
    setResendTimer(time);
    timer.current = setInterval(() => {
      time--;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const resendOtp = () => {
    if (resendTimer <= 0) {
      startTimer();
      API.resendOTP().catch((err) => {
        console.log(err);
      });
    }
  };

  function renderRegisterForm() {
    return (
      <p style={{color:"#fff", fontSize:"1.5rem", padding:"1rem"}}>Thank you for your interest,<br/> this contest is now closed</p>
      // <Formik
      //   key="register-form"
      //   initialValues={{
      //     mobile: "",
      //     // otp: "",
      //     smocker: true,
      //     aboveLegalDrinkingAge: false,
      //   }}
      //   validationSchema={RegisterValidation}
      //   onSubmit={(values, errors) => {
      //     console.log(values);
      //     // setShowOtpForm(true);
      //     setEnteredMobile(values.mobile);
      //     if (values.smocker && values.aboveLegalDrinkingAge) {
      //       API.getotp(values).then(() => {
      //         setShowOtpForm(true);
      //         startTimer();
      //       });
      //     } else {
      //       if (!values.smocker) {
      //         setCheckboxError("Please accept the terms and conditions");
      //       } else if (!values.aboveLegalDrinkingAge) {
      //         setCheckboxError(
      //           "Please confirm you are above legal drinking age"
      //         );
      //       }
      //       setShowError(true); // Optionally, show an error message
      //     }
      //   }}
      // >
      //   {({
      //     values,
      //     handleChange,
      //     handleSubmit,
      //     handleBlur,
      //     errors,
      //     touched,
      //     setFieldValue,
      //   }) => {
      //     return (
      //       <Form onSubmit={handleSubmit}>
      //         <div className="register-form">
      //           <p className="form-title ">
      //             Welcome To The <br />
      //             ‘Design Your Creative Pack’
      //             <br />
      //             contest.
      //           </p>
      //           <div className="input-group">
      //             <label className="input-label">Enter Mobile Number*</label>
      //             <input
      //               autoComplete="off"
      //               type="tel"
      //               onChange={handleChange}
      //               value={values.mobile}
      //               name="mobile"
      //               maxLength={10}
      //               onBlur={handleBlur}
      //               placeholder={t("registerPage.placeholder.mobile")}
      //             />
      //           </div>

      //           <div className="input-group">
      //             <label className="input-label">Enter OTP</label>
      //             <input
      //               autoComplete="off"
      //               type="tel"
      //               onChange={handleChange}
      //               // value={values.otp}
      //               name="otp"
      //               maxLength={6}
      //               onBlur={handleBlur}
      //               // placeholder={t("registerPage.placeholder.otp")}
      //               disabled={!showOtpForm} // Disable the OTP field initially
      //               style={{ backgroundColor: "#ff9494" }}
      //             />
      //           </div>

      //           <div className="input-group flex-center">
      //             <span className="smoker-text">Are you a smoker?</span>
      //             <label className="checkbox-container1">
      //               <span> {"Yes"} </span>

      //               <input
      //                 type="checkbox"
      //                 id="smocker"
      //                 name="smocker"
      //                 checked={values.smocker}
      //                 onChange={(e) => {
      //                   setFieldValue("smocker", e.target.checked);
      //                 }}
      //               />
      //               <span className="checkmark"></span>
      //             </label>

      //             <label className="checkbox-container1">
      //               <span> {"No"} </span>
      //               <input
      //                 type="checkbox"
      //                 id="smocker"
      //                 name="smocker"
      //                 checked={!values.smocker}
      //                 onChange={(e) => {
      //                   setFieldValue("smocker", !e.target.checked);
      //                 }}
      //               />
      //               <span className="checkmark"></span>
      //             </label>
      //           </div>

      //           <div className="input-group flex-center">
      //             <label className="checkbox-container">
      //               <span>
      //                 {" "}
      //                 I am older than 18years of age & <br />
      //                 agree to the{" "}
      //                 <span onClick={() => showModal(MODAL_TYPES.TERMS)}>
      //                   <u className="terms-link">Terms and Conditions{""}</u>
      //                 </span>
      //                 <br />
      //               </span>
      //               <input
      //                 type="checkbox"
      //                 id="aboveLegalDrinkingAge"
      //                 name="aboveLegalDrinkingAge"
      //                 onChange={(e) => {
      //                   setFieldValue(
      //                     "aboveLegalDrinkingAge",
      //                     e.target.checked
      //                   );
      //                 }}
      //               />
      //               <span className="checkmark"></span>
      //             </label>
      //           </div>

      //           {errors.mobile && touched.mobile && (
      //             <p className="error">{errors.mobile}</p>
      //           )}
      //           {!errors.mobile && errors.smocker && touched.smocker && (
      //             <p className="error">{errors.smocker}</p>
      //           )}

      //           {!errors.mobile &&
      //             !errors.smocker &&
      //             errors.aboveLegalDrinkingAge &&
      //             touched.aboveLegalDrinkingAge && (
      //               <p className="error">{errors.aboveLegalDrinkingAge}</p>
      //             )}
      //           <button className="btn btn-primary" type="submit">
      //             GET OTP <img src={arrow} alt="." />
      //           </button>
      //         </div>
      //       </Form>
      //     );
      //   }}
      // </Formik>
    );
  }

  function renderOtpForm(initialMobile: string) {
    return (
      <Formik
        key="otp-form"
        initialValues={{
          otp: "",
          smocker: true,
          aboveLegalDrinkingAge: true,
        }}
        validationSchema={OtpValidation}
        onSubmit={(values, errors) => {
          // navigate(ROUTES.DETAILS_PAGE);

          API.verifyOTP(
            values.otp,
            "",
            values.aboveLegalDrinkingAge,
            values.smocker
          )
            .then((response) => {
              hideCaptchaBadge();
              console.log("Access Token:", response.accessToken);
              console.log("name:", response);
              store.dispatch(setAccessToken(response.accessToken));
              store.dispatch(canClaim(response.canClaim));
              store.dispatch(
                isRegistered({
                  isRegistered: response.isRegistered,
                  userName: response.name,
                })
              );
              if (response.isRegistered) {
                // setShowPaymentPage(true);
                console.log("Navigating to payment page...");
                navigate(ROUTES.USER_PAGE);
              } else {
                console.log("Navigating to design page...");
                navigate(ROUTES.DETAILS_PAGE);
              }
              // setShowPayoutForm(true);
              // setShowPaymentPage(true);
              // navigate(ROUTES.DETAILS_PAGE);
              // navigate(ROUTES.PAYMENT_PAGE);
            })
            .catch((err) => {
              const { messageId } = err;
              switch (messageId) {
                case ERROR_IDS.INVALID_OTP:
                  errors.setErrors({
                    otp:
                      t("registerPage.errors.invalidOtp") ||
                      "Please enter a valid OTP",
                  });
                  break;
                default:
                  errors.setErrors({
                    otp: err.message,
                  });
                  break;
              }
            });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="otp-form">
                <p className="form-title ">
                  Welcome To The <br />
                  ‘Design Your Creative Pack’
                  <br />
                  contest.
                </p>
                <div className="input-group">
                  <label className="input-label">Enter Mobile Number*</label>
                  <input
                    autoComplete="off"
                    type="tel"
                    value={initialMobile} // Display the entered mobile number
                    name="mobile"
                    maxLength={10}
                    readOnly // Readonly to prevent editing
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.mobile")}
                  />
                </div>

                <div className="input-group">
                  <label className="input-label">Enter OTP</label>
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.otp}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.otp")}
                    disabled={!showOtpForm} // Disable the OTP field initially
                  />
                </div>

                <div className="flex-center timer-wrapper">
                  {resendTimer > 0 && (
                    <div className="timer flex-center">{resendTimer}</div>
                  )}
                  <span>{t("registerPage.didNotReceive")}</span>
                  <span className="link" onClick={resendOtp}>
                    {t("registerPage.resend")}
                  </span>
                </div>
                <div className="input-group flex-center">
                  <span className="smoker-text">Are you a smoker?</span>
                  <label className="checkbox-container1">
                    <span> {"Yes"} </span>

                    <input
                      type="checkbox"
                      id="smocker"
                      name="smocker"
                      checked={values.smocker}
                      onChange={(e) => {
                        setFieldValue("smocker", e.target.checked);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>

                  <label className="checkbox-container1">
                    <span> {"No"} </span>
                    <input
                      type="checkbox"
                      id="smocker"
                      name="smocker"
                      checked={!values.smocker}
                      onChange={(e) => {
                        setFieldValue("smocker", !e.target.checked);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                <div className="input-group flex-center">
                  <label className="checkbox-container">
                    <span>
                      {" "}
                      I am older than 18years of age & <br />
                      agree to the{" "}
                      <span onClick={() => showModal(MODAL_TYPES.TERMS)}>
                        <u className="terms-link">Terms and Conditions{""}</u>
                      </span>
                      <br />
                    </span>
                    <input
                      type="checkbox"
                      id="aboveLegalDrinkingAge"
                      name="aboveLegalDrinkingAge"
                      checked={values.aboveLegalDrinkingAge}
                      onChange={(e) => {
                        setFieldValue(
                          "aboveLegalDrinkingAge",
                          e.target.checked
                        );
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {errors.otp && touched.otp && (
                  <p className="error">{errors.otp}</p>
                )}
                {!errors.otp && errors.smocker && touched.smocker && (
                  <p className="error">{errors.smocker}</p>
                )}

                {!errors.otp &&
                  !errors.smocker &&
                  errors.aboveLegalDrinkingAge &&
                  touched.aboveLegalDrinkingAge && (
                    <p className="error">{errors.aboveLegalDrinkingAge}</p>
                  )}
              </div>
              <button
                className="btn btn-primary"
                type="submit"
                style={{ width: "235px" }}
              >
                VERIFY OTP <img src={arrow} alt="." />
              </button>
            </Form>
          );
        }}
      </Formik>
    );
  }
  function renderPayoutForm() {
    return (
      <CashbackPayout
        onSubmit={(values) => {
          console.log("Selected payout method and values:", values);
          setShowPayoutForm(false); // Hide the payout form after submission
        }}
      />
    );
  }

  return (
    <MainLayout className="register-page">
      {showOtpForm ? renderOtpForm(enteredMobile) : renderRegisterForm()}
    </MainLayout>
  );
};

export default Register;
