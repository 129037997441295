import { useNavigate, useLocation } from "react-router-dom";
import React, { useState } from "react";

import MainLayout from "../../../layouts/MainLayout";
import "./Element.scss";
import arrow from "../../../assets/images/arrow.png";
import { BLUE_COLOR, RED_COLOR } from "../Color/Color";

import { ROUTES } from "../../../lib/consts";
import API from "../../../api";

const Element = (props: any) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    selectedColor,
    selectedElement,
    selectedStyle,
    selectedValue,
    setSelectedStyle,
    setSelectedValue,
    setSelectedElement,
    setSelectedColor,
  } = props;

  const [error, setError] = useState("");

  const handleElementButtonClick = (element: string) => {
    // API.saveElementDetails(element);
    setError("");
    setSelectedElement(element);
    console.log(`Selected Style: ${element}, Selected Color: ${selectedColor}`);
  };

  const handleClaimReward = () => {
    if (!selectedElement) {
      setError("Please select a element");
    } else {
      setError("");
      API.saveElementDetails(selectedElement);
      navigate(ROUTES.DESIGN_TEXT);
    }
  };

  return (
    <>
      <div className="element-section">
        <div className="section-title">
          <p className="section-sub">Element</p>
          <p className="span">Beautify your pack by using your creativity!</p>
        </div>
        <div className="caraousel">
          <button
            className="element-button"
            style={{
              background:
                selectedElement === "strokes"
                  ? selectedColor === "warm"
                    ? RED_COLOR
                    : BLUE_COLOR
                  : "transparent",
              color:
                selectedElement === "strokes" && selectedColor !== null
                  ? "white"
                  : "#8c6629",
              border:
                selectedElement === "strokes" && selectedColor !== null
                  ? "transparent"
                  : "",
            }}
            onClick={() => handleElementButtonClick("strokes")}
          >
            Strokes
          </button>
          <button
            className="element-button"
            style={{
              background:
                selectedElement === "waves"
                  ? selectedColor === "warm"
                    ? RED_COLOR
                    : BLUE_COLOR
                  : "transparent",
              color:
                selectedElement === "waves" && selectedColor !== null
                  ? "white"
                  : "#8c6629",
              border:
                selectedElement === "waves" && selectedColor !== null
                  ? "transparent"
                  : "",
            }}
            onClick={() => handleElementButtonClick("waves")}
          >
            Waves
          </button>
          <button
            className="element-button"
            style={{
              background:
                selectedElement === "starlight"
                  ? selectedColor === "warm"
                    ? RED_COLOR
                    : BLUE_COLOR
                  : "transparent",
              color:
                selectedElement === "starlight" && selectedColor !== null
                  ? "white"
                  : "#8c6629",
              border:
                selectedElement === "starlight" && selectedColor !== null
                  ? "transparent"
                  : "",
            }}
            onClick={() => handleElementButtonClick("starlight")}
          >
            Starlight Streaks
          </button>
        </div>
      </div>
      {error && <div className="error-mesg">{error}</div>}
      <div className="btn-container">
        <button className="open-text" onClick={handleClaimReward}>
          NEXT <img src={arrow} alt="." />
        </button>
      </div>
    </>
  );
};

export default Element;
