import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import { GlobalLoader } from "./helpers/GlobalLoader";
import { GlobalModal } from "./helpers/GlobalModal";
import "./i18n/config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router basename={process.env.REACT_APP_BASE_URL}>
        <ScrollToTop />
        <GlobalLoader>
          <GlobalModal>
            <App />
          </GlobalModal>
        </GlobalLoader>
      </Router>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
