// CashbackPayout.tsx
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState, useRef } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "./PaymentPage.scss";
import { toBase64 } from "../../../lib/utils";
import API from "../../../api";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../lib/consts";
import { error } from "console";
// import Gate from "../../assets/images/hellgate.png";
import pack from "../../../assets/images/Packshot.png";
import arrow from "../../../assets/images/arrow.png";
import neft from "../../assets/images/neft.svg";
import neftselected from "../../assets/images/neft-selected.svg";
import upiselected from "../../assets/images/upi-selected.svg";
import upi from "../../assets/images/upi.svg";
import { useAuthentication } from "../../../hooks/useAuthentication";

interface PaymentPageProps {
  onSubmit: (values: any) => void;
}

const PaymentPage: React.FC<PaymentPageProps> = ({ onSubmit }) => {
  const PayoutValidation = Yup.object().shape({
    payoutMethod: Yup.string().required("Please choose a payout method"),
    upiNumber: Yup.string().when("payoutMethod", (payoutMethod, schema) =>
      payoutMethod.toString() === "upi"
        ? schema
            .required("Enter valid UPI")
            .matches(
              /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/,
              "Enter valid UPI"
            )
        : schema
    ),
    // name: Yup.string().when("payoutMethod", (payoutMethod, schema) =>
    //   payoutMethod.toString() === "neft"
    //     ? schema
    //         .required("Enter valid account name")
    //         .matches(/^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/, "Enter valid account name")
    //     : schema
    // ),

    // accountNumber: Yup.string().when("payoutMethod", (payoutMethod, schema) =>
    //   payoutMethod.toString() === "neft"
    //     ? schema
    //         .required("Enter valid account number")
    //         .matches(/^\d{7,20}$/, "Enter valid account number")
    //     : schema
    // ),
    // ifscCode: Yup.string().when("payoutMethod", (payoutMethod, schema) =>
    //   payoutMethod.toString() === "neft"
    //     ? schema
    //         .required("Enter valid IFSC code")
    //         .matches(/^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/, "Enter valid IFSC code")
    //     : schema
    // ),
  });

  const navigate = useNavigate();

  return (
    <>
      <div className="">
        <p className="payment-pack-container-txt">
          You also stand a chance to
          <br />
          win ₹20 cashback, Please enter
          <br />
          your UPI ID to claim
        </p>
        <Formik
          initialValues={{
            payoutMethod: "upi",
            upiNumber: "",
          }}
          validationSchema={PayoutValidation}
          onSubmit={(values, { setSubmitting }) => {
            // Add logic for handling the selected payout method
            // console.log(values);

            // switch (values.payoutMethod) {
            //   case "upi":
            API.saveUPIDetails(values.upiNumber);
            // gtagTrackEvent(GA_EVENTS.UPI_click);
            //     break;
            //   case "neft":
            //     API.saveNEFTDetails(
            //       values.name,
            //       values.accountNumber,
            //       values.ifscCode
            //     );
            //     // gtagTrackEvent(GA_EVENTS.NEFT_click);
            //     break;
            //   default:
            //     break;
            // }
            onSubmit(values);
            setSubmitting(false);
            //   console.log(errors);
            // Navigate to the Thank You page
            // navigate(ROUTES.PAYOUT_FORM);
            navigate(ROUTES.DESIGN_THANKYOU);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            touched,

            errors,
          }) => (
            <Form className="cashback-payout-form" onSubmit={handleSubmit}>
              <div className="payment-input-container">
                {values.payoutMethod === "upi" && (
                  <div className="input-group">
                    <label htmlFor="upiNumber"></label>
                    <Field
                      type="text"
                      id="upiNumber"
                      name="upiNumber"
                      placeholder="Enter UPI Id"
                    />
                    <ErrorMessage
                      name="upiNumber"
                      component="p"
                      className="error-payment"
                    />
                  </div>
                )}
              </div>
              <div className="payment-btn-container">
                <button className="btn btn-primary" type="submit">
                  SUBMIT
                  <img src={arrow} alt="." />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PaymentPage;
