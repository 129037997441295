import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="terms-popup" title="Terms & Conditions">
      {/*<p dir="ltr">Coming Soon</p>*/}
      <strong>
        <p dir="ltr">TERMS AND CONDITIONS</p>
      </strong>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            THIS ACTIVITY WILL START ON 1st APRIL, 2024 AT 6:00 AM AND END ON
            30th JUNE, 2024 11:59 PM. NO ENTRY OR REGISTRATION WILL BE
            ENTERTAINED AFTER 11:59PM ON 30th JUNE, 2024.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            THIS ACTIVITY IS OPEN ONLY TO PERSONS OF AGE 18 (EIGHTEEN) YEARS AND
            ABOVE, AS ON 1st APRIL, 2024. PARTICIPATION IN THIS ACTIVITY IS
            PURELY VOLUNTARY. PARTICIPATION IN THIS ACTIVITY IMPLIES
            UNCONDITIONAL ACCEPTANCE OF THESE TERMS AND CONDITIONS.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">THIS ACTIVITY IS FOR ALL INDIA EXCEPT TAMIL NADU</p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            THE CONTEST IS NOT APPLICABLE IN OR FOR THE RESIDENTS OF THE STATE
            OF TAMIL NADU. ANY MISSED CALL OR PARTICIPATION RECEIVED FROM THE
            RESIDENTS OF STATES OTHER THAN THOSE SPECIFIED ABOVE WILL NOT BE
            CONSIDERED. PARTICIPATION IN THIS ACTIVITY WILL BE TREATED AS VOID
            AB INITIO WHEREVER SUCH ACTIVITIES ARE SPECIFICALLY PROHIBITED UNDER
            LOCAL LAWS.
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">THIS PACK IS ALSO AVAILABLE WITHOUT THIS ACTIVITY.</p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            THE ELIGIBLE INDIVIDUALS WHO INTEND TO PARTICIPATE IN THIS ACTIVITY
            SHOULD REGISTER THEMSELVES ON{" "}
            <a href="http://www.designyourcreativepack.com">
              WWW.DESIGNYOURCREATIVEPACK<br/>.COM
            </a>
            . ALL ELIGIBLE INDIVIDUALS SHALL, AFTER REGISTERING THEMSELVES,
            SELECT THE DESIGN ELEMENTS IN THE SITE, MAKE THE FINAL DESIGN &amp;
            SUBMIT IT.
          </p>
        </li>
      </ol>
      <ol start={7}>
        <li dir="ltr">
          <p dir="ltr">
            THE ENTRIES SHALL BE JUDGED ON THE BASIS OF ENGAGEMENT TIME AND
            INNOVATIVENESS OF THE DESIGN OUTPUT OF THE PACK.
          </p>
        </li>
      </ol>
      <ol start={8}>
        <li dir="ltr">
          <p dir="ltr">
            ALL ELIGIBLE PARTICIPANTS COMPLETING THE ACTIVITY SHALL RECEIVE AN
            ASSURED PRIZE OF A DIGITAL CASHBACK OF Rs 20. EACH MOBILE NUMBER IS
            ELIGIBLE FOR ASSURED CASHBACK FOR ONCE ONLY.
          </p>
        </li>
      </ol>
      <ol start={9}>
        <li dir="ltr">
          <p dir="ltr">THE WINNERS WILL BE GRATIFIED AS PER BELOW:</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            TOP 50 SELECTED ENTRIES WILL WIN A SAMSUNG M13 6GB 128GB.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            THE NEXT 500 SELECTED ENTRIES WILL WIN E-COMMERCE VOUCHER WORTH Rs
            500.
          </p>
        </li>
      </ol>
      <ol start={10}>
        <li dir="ltr">
          <p dir="ltr">
            THE WINNERS SHALL BE INFORMED OF THE PRIZES THEY HAVE WON THROUGH
            SMS BY 30th JUNE, 2024 ON OR BEFORE 12:00 PM.
          </p>
        </li>
      </ol>
      <ol start={11}>
        <li dir="ltr">
          <p dir="ltr">
            THE PRIZE(S) SHALL BE GIVEN TO THE RESPECTIVE WINNING PARTICIPANTS,
            SUBJECT TO THE WINNER PRESENTING THE ORIGINAL PACKAGING INSERT, A
            VALID PROOF OF IDENTITY, ADDRESS AND AGE. ITC LIMITED SHALL NOT BE
            LIABLE FOR NON-RECEIPT OF THE PRIZE DUE TO INCORRECT DETAILS
            PROVIDED BY THE WINNER. NO ADDITIONAL CLAIM SHALL BE ENTERTAINED.
          </p>
        </li>
      </ol>
      <ol start={12}>
        <li dir="ltr">
          <p dir="ltr">
            A DAMAGED OR ILLEGIBLE PACKAGING INSERT MAY BE REJECTED.
          </p>
        </li>
      </ol>
      <ol start={13}>
        <li dir="ltr">
          <p dir="ltr">
            THERE IS NO WARRANTY OR ASSURANCE AS TO THE QUALITY OF PRIZES WON.
            THE PRIZES IN OUR COMMUNICATION ARE MEANT FOR ILLUSTRATION PURPOSE
            ONLY, ACTUAL PRIZES MAY DIFFER. THE ACTUAL PRIZE WON MAY DIFFER IN
            SIZE, SHAPE OR COLOUR AS COMPARED TO THE ONES DEPICTED IN
            COMMUNICATIONS. THE PRIZE WON IS NON-TRANSFERABLE AND CANNOT BE
            EXCHANGED OR REDEEMED FOR CASH.
          </p>
        </li>
      </ol>
      <ol start={14}>
        <li dir="ltr">
          <p dir="ltr">
            THE PARTICIPANTS MUST NOT SUBMIT UNLAWFUL, OFFENSIVE, ABUSIVE,
            PORNOGRAPHIC, HARASSING, BLASPHEMOUS, DEFAMATORY, OBSCENE, HATEFUL,
            RACIALLY OR ETHICALLY OBJECTIONABLE, DISPARAGING OR OTHERWISE
            INAPPROPRIATE MATERIAL AND AGREES TO OBTAIN ANY CONSENT, PERMISSION
            OR LICENSE THAT MAY BE LEGALLY REQUIRED TO SUBMIT ANY MATERIAL OR
            CONTENT AND RESPECT THE PRIVACY OF OTHERS.
          </p>
        </li>
      </ol>
      <ol start={15}>
        <li dir="ltr">
          <p dir="ltr">
            ITC LIMITED (“ITC”) RESERVES THE RIGHT TO REJECT THE CLAIM IF ANY OF
            THE TERMS OR CONDITIONS ARE NOT MET. ITC WILL NOT BE LIABLE IN ANY
            MANNER FOR ANY CLAIM ARISING OUT OF OR IN RESPECT OF THIS ACTIVITY.
          </p>
        </li>
      </ol>
      <ol start={16}>
        <li dir="ltr">
          <p dir="ltr">
            ITC UNILATERALLY RESERVES THE RIGHT TO CHANGE THE TERMS AND
            CONDITIONS OF THIS ACTIVITY INCLUDING EXTENDING, WITHDRAWING, OR
            DISCONTINUING THE SAME WITHOUT NOTICE OR ASSIGNING ANY REASON, AT
            ITS SOLE DISCRETION AND WITHOUT ANY LIABILITY.
          </p>
        </li>
      </ol>
      <ol start={17}>
        <li dir="ltr">
          <p dir="ltr">
            ALL THE INTELLECTUAL PROPERTY RIGHTS (“IPR”) ASSOCIATED WITH THE
            DESIGNS SUBMITTED BY THE PARTICIPANTS SHALL VEST WITH ITC, AND BY
            PARTICIPATING IN THIS ACITIVITY, THE PARTICIPANTS UNDERTAKES AND
            ASSURES NOT TO MAKE ANY SUCH CLAIMS IN ANY PROCEEDINGS OR OTHERWISE.
          </p>
        </li>
      </ol>
      <ol start={18}>
        <li dir="ltr">
          <p dir="ltr">
            BY PARTICIPATING IN THIS ACTIVITY, THE PARTICIPANTS CONSENT TO
            COLLECTION, STORING, PROCESSING AND USE OF PERSONAL INFORMATION OF
            THE PARTICIPANTS BY ITC AND ITS SERVICE PROVIDERS.
          </p>
        </li>
      </ol>
      <ol start={19}>
        <li dir="ltr">
          <p dir="ltr">
            THIS ACTIVITY SHALL BE GOVERNED BY THE PRIVACY POLICY OF ITC
            PUBLISHED HERE
            <a
              href="https://www.itcportal.com/about-itc/policies/privacy-policy.aspx"
              target="_blank"
            >
              https://www.itcportal.com/about-itc/policies/privacy-policy.aspx
            </a>
          </p>
        </li>
      </ol>
      <ol start={20}>
        <li dir="ltr">
          <p dir="ltr">
            EMPLOYEES OF ITC AND THEIR FAMILY MEMBERS ARE NOT ENTITLED TO
            PARTICIPATE IN THIS ACTIVITY.
          </p>
        </li>
      </ol>
      <ol start={21}>
        <li dir="ltr">
          <p dir="ltr">
            ALL DISPUTES ARE SUBJECT TO THE EXCLUSIVE JURISDICTION OF THE COURTS
            AT KOLKATA.
          </p>
        </li>
      </ol>
    </Popup>
  );
};

export default TermsPopup;
