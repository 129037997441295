import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../../layouts/MainLayout";
import "./Text.scss";
import { ROUTES } from "../../../lib/consts";
import { useEffect, useState } from "react";
import API from "../../../api";
import pack from "../../assets/images/Packshot.png";
import arrow from "../../../assets/images/arrow.png";
import store from "../../../store/store";
import {
  alreadyDesigned,
  isCashbackWinner,
} from "../../../store/actions/authAction";

const Text = (props: any) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const [isAlreadyDesigned, setIsAlreadyDesigned] = useState<boolean>(false);

  const { text, setText } = props;

  const handleChange = (e: any) => {
    if (!e.target) return;
    setText(e.target.value);
    setError("");
  };

  const handleClaimReward = async () => {
    if (!text) {
      setError("Please enter your name.");
      return;
    }

    try {
      const imageResponse = await API.saveNameDetails(text, "");

      const imageUrl = imageResponse.url;
      localStorage.setItem("image-url", imageUrl);
      setUrl(imageUrl);

      setIsAlreadyDesigned(imageResponse.alreadyDesigned);
      store.dispatch(isCashbackWinner(imageResponse.isCashbackWinner));
      store.dispatch(alreadyDesigned(imageResponse.alreadyDesigned));
      sessionStorage.setItem(
        "alreadyDesigned",
        imageResponse.alreadyDesigned ? "yes" : "no"
      );
      console.log(imageResponse.alreadyDesigned);
      console.log(imageResponse);

      // Navigate after all state updates and dispatch actions
      navigate(ROUTES.DOWNLOAD);
    } catch (error) {
      setError("Enter valid name.");
      console.error("Error saving name details:", error);
    }
  };

  return (
    <>
      <div className="name-page-container">
        <p className="name-page-text">
          Own your design with your <br />
          unique signature. Enter your
          <br /> name to be put on the pack.
        </p>
        <label className="name-page-input-label">
          Enter Name <br />
          Only 6 characters are accepted.
        </label>
        <p className="characters"></p>
        <input
          className="text-input"
          autoComplete="off"
          type="text"
          onChange={(e) => {
            const { name, value } = e.target;
            handleChange({ target: { name, value: value.toUpperCase() } });
          }}
          value={text.toUpperCase()}
          name="name"
          maxLength={6}
          placeholder="Enter text"
        />
      </div>
      {error && <div className="error-mesg">{error}</div>}
      <div className="btn-container">
        <button className="open-text" onClick={handleClaimReward}>
          NEXT <img src={arrow} alt="." />
        </button>
      </div>
    </>
  );
};

export default Text;
