import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../../layouts/MainLayout";
import React, { useState } from "react";

import "./Value.scss";
import { ROUTES } from "../../../lib/consts";
import arrow from "../../../assets/images/arrow.png";
import { BLUE_COLOR, RED_COLOR } from "../Color/Color";
import API from "../../../api";

const Value = (props: any) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    selectedColor,
    selectedElement,
    selectedStyle,
    selectedValue,
    setSelectedStyle,
    setSelectedValue,
    setSelectedElement,
    setSelectedColor,
  } = props;
  const [error, setError] = useState("");

  // const handleColorOptionClick = (color: string) => {
  //   setSelectedColor(color);
  // };

  const handlevalueButtonClick = (value: string) => {
    // API.saveValueDetails(value);
    setError("");
    setSelectedValue(value);
    console.log(`Selected Style: ${value}, Selected Color: ${selectedColor}`);
  };

  const handleClaimReward = () => {
    if (!selectedValue) {
      setError("Please select a value");
    } else {
      setError("");
      API.saveValueDetails(selectedValue);
      navigate(ROUTES.DESIGN_ELEMENT);
    }
  };

  return (
    <>
      {/*<div className="color-palette">
        <p className="color-palette-txt">Color palatte</p>
        <button
          className="color-option1"
          style={{
            background: selectedColor === RED_COLOR ? RED_COLOR : "",
            border: selectedColor === RED_COLOR ? "2px solid #FFF" : "",
            height: selectedColor === RED_COLOR ? "45px" : "35px",
            width: selectedColor === RED_COLOR ? "45px" : "35px",
            boxShadow:
              selectedColor === RED_COLOR
                ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                : "",
          }}
          onClick={() => handleColorOptionClick(RED_COLOR)}
        ></button>
        <button
          className="color-option2"
          style={{
            background: selectedColor === BLUE_COLOR ? BLUE_COLOR : "",
            border: selectedColor === BLUE_COLOR ? "2px solid #FFF" : "white",
            height: selectedColor === BLUE_COLOR ? "45px" : "35px",
            width: selectedColor === BLUE_COLOR ? "45px" : "35px",
            boxShadow:
              selectedColor === BLUE_COLOR
                ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                : "",
          }}
          onClick={() => handleColorOptionClick(BLUE_COLOR)}
        ></button>
        </div>*/}
      {/*<div className="style-section">
        <div className="section-title">
          <p className="section-sub" style={{ marginTop: "0" }}>
            Style
          </p>
          <p className="span">Choose the style that resonates with you!</p>
        </div>
        <div className="caraousel">
          <button
            className="style-button"
            style={{
              background:
                selectedStyle === "Urban Centric" && selectedColor !== null
                  ? selectedColor
                  : "transparent",
              color:
                selectedStyle === "Urban Centric" && selectedColor !== null
                  ? "white"
                  : "#8c6629",
            }}
            onClick={() => handleStyleButtonClick("Urban Centric")}
          >
            Urban Centric
          </button>
          <button
            className="style-button"
            style={{
              background:
                selectedStyle === "Classic Elegance" && selectedColor !== null
                  ? selectedColor
                  : "transparent",
            }}
            onClick={() => handleStyleButtonClick("Classic Elegance")}
          >
            Classic Elegance
          </button>
          <button
            className="style-button"
            style={{
              background:
                selectedStyle === "Futuristic Fusion" && selectedColor !== null
                  ? selectedColor
                  : "transparent",
            }}
            onClick={() => handleStyleButtonClick("Futuristic Fusion")}
          >
            Futuristic Fusion
          </button>
        </div>
        </div>*/}
      <div className="value-section">
        <div className="section-title">
          <p className="section-sub">Value</p>
          <p className="span">Choose the value that resonates with you!</p>
        </div>
        <div className="caraousel">
          <button
            className="value-button"
            style={{
              background:
                selectedValue === "fashion"
                  ? selectedColor === "warm"
                    ? RED_COLOR
                    : BLUE_COLOR
                  : "transparent",
              color:
                selectedValue === "fashion" && selectedColor !== null
                  ? "white"
                  : "#8c6629",
              border:
                selectedValue === "fashion" && selectedColor !== null
                  ? "transparent"
                  : "",
            }}
            onClick={() => handlevalueButtonClick("fashion")}
          >
            Fashion Forward
          </button>
          <button
            className="value-button"
            style={{
              background:
                selectedValue === "youth"
                  ? selectedColor === "warm"
                    ? RED_COLOR
                    : BLUE_COLOR
                  : "transparent",
              color:
                selectedValue === "youth" && selectedColor !== null
                  ? "white"
                  : "#8c6629",
              border:
                selectedValue === "youth" && selectedColor !== null
                  ? "transparent"
                  : "",
            }}
            onClick={() => handlevalueButtonClick("youth")}
          >
            Youthful Vibes
          </button>
          <button
            className="value-button"
            style={{
              background:
                selectedValue === "culture"
                  ? selectedColor === "warm"
                    ? RED_COLOR
                    : BLUE_COLOR
                  : "transparent",
              color:
                selectedValue === "culture" && selectedColor !== null
                  ? "white"
                  : "#8c6629",
              border:
                selectedValue === "culture" && selectedColor !== null
                  ? "transparent"
                  : "",
            }}
            onClick={() => handlevalueButtonClick("culture")}
          >
            Culturally Connected
          </button>
        </div>
      </div>
      {/*<div className="element-section">
        <div className="section-title">
          <p className="section-sub">Element</p>
          <p className="span">Beautify your pack by using your creativity!</p>
        </div>
        <div className="caraousel">
          <button
            className="element-button"
            style={{
              background:
                selectedElement === "Strokes" && selectedColor !== null
                  ? selectedColor
                  : "transparent",
            }}
            onClick={() => handleElementButtonClick("Strokes")}
          >
            Strokes
          </button>
          <button
            className="element-button"
            style={{
              background:
                selectedElement === "Waves" && selectedColor !== null
                  ? selectedColor
                  : "transparent",
            }}
            onClick={() => handleElementButtonClick("Waves")}
          >
            Waves
          </button>
          <button
            className="element-button"
            style={{
              background:
                selectedElement === "Starlight Streaks" &&
                selectedColor !== null
                  ? selectedColor
                  : "transparent",
            }}
            onClick={() => handleElementButtonClick("Starlight Streaks")}
          >
            Starlight Streaks
          </button>
          </div>
      </div>*/}
      {error && <div className="error-mesg">{error}</div>}

      <div className="btn-container">
        <button className="open-text" onClick={handleClaimReward}>
          NEXT <img src={arrow} alt="." />
        </button>
      </div>
    </>
  );
};

export default Value;
