import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const ContactUsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="contact-us-popup">
      <p className="text1">CONTACT US</p>
      <p className="text3">
        {/*<a href={"mailto:hideandseek@parle.com"}>hideandseek@parle.com</a>*/}
      </p>
      <p className="text3">
        {/*<a href={"tel:+08069806393"}>080-69806393</a>*/}
      </p>
    </Popup>
  );
};

export default ContactUsPopup;
