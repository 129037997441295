import { Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ROUTES } from "./lib/consts";
// import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register";
import Details from "./pages/Details/Details";
import User from "./pages/User/User";
import UploadSelfie from "./pages/UploadSelfie/UploadSelfie";
import Completed from "./pages/Completed/Completed";
import { useEffect, useState } from "react";
import API from "./api";
import { useGlobalLoaderContext } from "./helpers/GlobalLoader";
import store from "./store/store";
import { setUserIdentification } from "./store/actions/authAction";
import { addCaptchaScript, logoutUser } from "./lib/utils";
import { useAuthentication } from "./hooks/useAuthentication";
import PrivateRoute from "./helpers/PrivateRoute";
import PaymentPage from "./pages/Design/PaymentPage/PaymentPage";
import CashbackPayout from "./pages/CashbackPayout/CashbackPayout";
import Design from "./pages/Design/Design";
import Color from "./pages/Design/Color/Color";
import Text from "./pages/Design/Text/Text";
import Style from "./pages/Design/Style/Style";
import Value from "./pages/Design/Value/Value";
import Element from "./pages/Design/Element/Element";
import Download from "./pages/Completed/Completed";
import Thankyou from "./pages/Design/Thankyou/Thankyou";
import { useNavigate } from "react-router-dom";

function App() {
  const { showLoader, hideLoader } = useGlobalLoaderContext();
  const { isLoggedIn } = useAuthentication();
  const [selectedStyle, setSelectedStyle] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedElement, setSelectedElement] = useState<string | null>(null);
  const [selectedColor, setSelectedColor] = useState<string | null>("warm");
  const [text, setText] = useState<string | null>("");
  const navigate = useNavigate();

  useEffect(() => {
    API.initialize(showLoader, hideLoader);
    API.createUser().then((response: any) => {
      store.dispatch(setUserIdentification(response));
      if (!response.isLoggedIn && isLoggedIn) {
        logoutUser();
        toast.info("Your session has been expired");
      }
      // addCaptchaScript();
    });
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      logoutUser();
      setSelectedStyle(null);
      setSelectedValue(null);
      setSelectedElement(null);
      setSelectedColor("warm");
      setText("");
      navigate(ROUTES.REGISTER);
      toast.info("Your session has been expired");
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path={ROUTES.REGISTER} element={<Register />} />

        <Route
          path={ROUTES.DETAILS_PAGE}
          element={
            <PrivateRoute>
              <Details />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.USER_PAGE}
          element={
            <PrivateRoute>
              <User />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.PAYOUT_FORM}
          element={
            <PrivateRoute>
              <CashbackPayout onSubmit={(values) => console.log(values)} />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.DESIGN}
          element={
            <PrivateRoute>
              <Design
                selectedStyle={selectedStyle}
                selectedValue={selectedValue}
                selectedElement={selectedElement}
                selectedColor={selectedColor}
                text={text}
              />
            </PrivateRoute>
          }
        >
          <Route
            path={ROUTES.DESIGN_PAYMENT}
            element={
              <PrivateRoute>
                <PaymentPage onSubmit={(values) => console.log(values)} />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.DESIGN_STYLE}
            element={
              <PrivateRoute>
                <Style
                  selectedElement={selectedElement}
                  selectedStyle={selectedStyle}
                  selectedValue={selectedValue}
                  selectedColor={selectedColor}
                  setSelectedStyle={setSelectedStyle}
                  setSelectedValue={setSelectedValue}
                  setSelectedElement={setSelectedElement}
                  setSelectedColor={setSelectedColor}
                />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.DESIGN_VALUE}
            element={
              <PrivateRoute>
                <Value
                  selectedElement={selectedElement}
                  selectedStyle={selectedStyle}
                  selectedValue={selectedValue}
                  selectedColor={selectedColor}
                  setSelectedStyle={setSelectedStyle}
                  setSelectedValue={setSelectedValue}
                  setSelectedElement={setSelectedElement}
                  setSelectedColor={setSelectedColor}
                />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.DESIGN_ELEMENT}
            element={
              <PrivateRoute>
                <Element
                  selectedElement={selectedElement}
                  selectedStyle={selectedStyle}
                  selectedValue={selectedValue}
                  selectedColor={selectedColor}
                  setSelectedStyle={setSelectedStyle}
                  setSelectedValue={setSelectedValue}
                  setSelectedElement={setSelectedElement}
                  setSelectedColor={setSelectedColor}
                />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.DESIGN_COLOR}
            element={
              <PrivateRoute>
                <Color
                  selectedElement={selectedElement}
                  selectedStyle={selectedStyle}
                  selectedValue={selectedValue}
                  selectedColor={selectedColor}
                  setSelectedStyle={setSelectedStyle}
                  setSelectedValue={setSelectedValue}
                  setSelectedElement={setSelectedElement}
                  setSelectedColor={setSelectedColor}
                />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.DESIGN_THANKYOU}
            element={
              <PrivateRoute>
                <Thankyou
                // selectedElement={selectedElement}
                // selectedStyle={selectedStyle}
                // selectedValue={selectedValue}
                // selectedColor={selectedColor}
                // setSelectedStyle={setSelectedStyle}
                // setSelectedValue={setSelectedValue}
                // setSelectedElement={setSelectedElement}
                // setSelectedColor={setSelectedColor}
                />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.DESIGN_TEXT}
            element={
              <PrivateRoute>
                <Text text={text} setText={setText} />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path={ROUTES.DOWNLOAD}
          element={
            <PrivateRoute>
              <Download />
            </PrivateRoute>
          }
        />
      </Routes>
      <ToastContainer position="bottom-center" hideProgressBar={true} />
    </div>
  );
}

export default App;
