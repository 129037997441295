// CashbackPayout.tsx
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState, useRef } from "react";
import CameraIcon from "../../assets/images/camera-icon.png";
import MainLayout from "../../layouts/MainLayout";
import "./CashbackPayout.scss";
import { toBase64 } from "../../lib/utils";
import API from "../../api";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/consts";
import { Trans, useTranslation } from "react-i18next";
import { error } from "console";
import Gate from "../../assets/images/hellgate.png";
import Gateleft from "../../assets/images/hellgateleft.png";
import pack from "../../assets/images/Packshot.png";
import arrow from "../../assets/images/arrow.png";
import { useAuthentication } from "../../hooks/useAuthentication";
import { toast } from "react-toastify";

interface CashbackPayoutProps {
  onSubmit: (values: any) => void;
}

const CashbackPayout: React.FC<CashbackPayoutProps> = ({ onSubmit }) => {
  const [isGateOpen, setGateOpen] = useState(false);
  const [isButtonHidden, setButtonHidden] = useState(false);
  const navigate = useNavigate();
  const audioRef = useRef<HTMLAudioElement>(null);
  const { userKey } = useAuthentication();

  // const handleButtonClick = async () => {
  //   // setButtonHidden(true);
  //   // try {
  //   //   await API.action("ready");
  //   navigate(ROUTES.DESIGN_STYLE);
  //   // } catch (error) {
  //   //   console.log("error calling action api:", error);
  //   // }
  // };

  const handleButtonClick = async () => {
    // setButtonHidden(true);
    API.action("ready");
    navigate(ROUTES.DESIGN_STYLE);
  };

  return (
    <MainLayout className="gate-page">
      <div className="welcome-text" style={{ top: "1em" }}>
        <p>‘Design Your Creative Pack’</p>
      </div>
      <div className="pack-container">
        <img className="first-pack" src={pack} alt="pack" />
        <p className="pack-container-txt">
          Start designing the pack by <br />
          selecting your preferences
        </p>
        <div className="cashback-btn-container">
          <button className="open-text" onClick={handleButtonClick}>
            READY
            <img src={arrow} alt="." />
          </button>
        </div>
      </div>
    </MainLayout>
  );
};

export default CashbackPayout;
