import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../../layouts/MainLayout";

import "./Color.scss";
import arrow from "../../../assets/images/arrow.png";
import { useEffect, useState } from "react";

import { ROUTES } from "../../../lib/consts";
import API from "../../../api";
export const RED_COLOR = "linear-gradient(90deg, #ff9e15 0%, #ef184b 70%)";
export const BLUE_COLOR = "linear-gradient(90deg, #8293C7 0%, #2F2C93 100%)";

const Color = (props: any) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    selectedColor,
    selectedElement,
    selectedStyle,
    selectedValue,
    setSelectedStyle,
    setSelectedValue,
    setSelectedElement,
    setSelectedColor,
  } = props;

  const [error, setError] = useState("");

  useEffect(() => {
    // Reset selectedColor to an empty string when the component mounts
    setSelectedColor("");
  }, []);
  const handleColorOptionClick = (color: string) => {
    setError("");
    setSelectedColor(color);
    // API.saveColourDetails(color);
    console.log(`Selected Color: ${selectedColor}`);
  };

  const handleClaimReward = () => {
    if (!selectedColor) {
      setError("Please select a color.");
    } else {
      setError("");
      API.saveColourDetails(selectedColor);
      navigate(ROUTES.DESIGN_VALUE);
    }
  };

  return (
    <>
      <div className="color-palette">
        <p className="color-palette-txt">Color palatte</p>
        <p className="color-palette-txt1">Choose Color Type</p>
        <button
          className="color-option1"
          style={{
            background: selectedColor === "warm" ? RED_COLOR : "",
            border: selectedColor === "warm" ? "transparent" : "",
            color: selectedColor === "warm" ? "#fff" : "#b7924c",
            boxShadow:
              selectedColor === "warm"
                ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                : "",
          }}
          onClick={() => handleColorOptionClick("warm")}
        >
          Warm
        </button>
        <button
          className="color-option2"
          style={{
            background: selectedColor === "cool" ? BLUE_COLOR : "",
            border: selectedColor === "cool" ? "transparent" : "",
            color: selectedColor === "cool" ? "#fff" : "#b7924c",
            boxShadow:
              selectedColor === "cool"
                ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                : "",
          }}
          onClick={() => handleColorOptionClick("cool")}
        >
          Cool
        </button>
      </div>
      {error && <div className="error-mesg">{error}</div>}
      <div className="btn-container">
        <button className="open-text" onClick={handleClaimReward}>
          NEXT <img src={arrow} alt="." />
        </button>
      </div>
    </>
  );
};

export default Color;
