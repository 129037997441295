import { getCookie, setCookie } from "../../lib/utils";
import { alreadyDesigned, isCashbackWinner } from "../actions/authAction";
import {
  SET_USER_ID,
  CLEAR_ACCESS_DETAILS,
  SET_ACCESS_TOKEN,
  CAN_CLAIM,
  IS_CASHBACK_WINNER,
  ALREADY_DESIGNED,
  IS_USER_REGISTERED,
} from "../types";

const initialState = {
  userName: "",
  alreadyRegisterd: false,
  userKey: "",
  dataKey: "",
  accessToken: "",
  canClaim: false,
  isCashbackWinner: false,
  alreadyDesigned: false,
};

type ActionType =
  | {
      type: "SET_USER_ID";
      payload: {
        userKey: string;
        dataKey: string;
      };
    }
  | {
      type: "SET_ACCESS_TOKEN";
      payload: string;
    }
  | {
      type: "CLEAR_ACCESS_DETAILS";
    }
  | {
      type: "ALREADY_DESIGNED";
      payload: {
        alreadydesigned: boolean;
      };
    }
  | {
      type: "CAN_CLAIM";
      payload: {
        canClaim: boolean;
      };
    }
  | {
      type: "IS_CASHBACK_WINNER";
      payload: {
        isCashbackWinner: boolean;
      };
    }
  | {
      type: "IS_USER_REGISTERED";
      payload: {
        isUserRegistered: boolean;
        userName: string;
      };
    };
export default function authReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case SET_USER_ID:
      let masterKey = getCookie("lucifers-id");
      if (!masterKey) {
        setCookie("lucifers-id", action.payload.userKey);
      }
      // console.log("userKey", action.payload.userKey);
      return {
        ...state,
        userKey: action.payload.userKey,
        dataKey: action.payload.dataKey,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case CLEAR_ACCESS_DETAILS:
      return {
        ...state,
        userKey: "",
        dataKey: "",
        accessToken: "",
      };
    case CAN_CLAIM:
      return {
        ...state,
        canClaim: action.payload.canClaim,
      };
    case IS_CASHBACK_WINNER:
      return {
        ...state,
        isCashbackWinner: action.payload.isCashbackWinner,
      };
    case IS_USER_REGISTERED:
      return {
        ...state,
        isUserRegistred: action.payload.isUserRegistered,
        userName: action.payload.userName,
      };
    case ALREADY_DESIGNED:
      console.log(action.payload);
      return {
        ...state,
        alreadyDesigned: action.payload.alreadydesigned,
      };
    default:
      return state;
  }
}
