import MainLayout from "../../layouts/MainLayout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./Details.scss";
import { useEffect, useRef, useState } from "react";
import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, ROUTES } from "../../lib/consts";
import store from "../../store/store";
import { canClaim, setAccessToken } from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import PaymentPage from "../Design/PaymentPage/PaymentPage";
import CashbackPayout from "../CashbackPayout/CashbackPayout";
import { useAuthentication } from "../../hooks/useAuthentication";
import { addCaptchaScript, hideCaptchaBadge } from "../../lib/utils";
import { setUserIdentification } from "../../store/actions/authAction";
import { error, log } from "console";
import arrow from "../../assets/images/arrow.png";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";

const Details = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();
  const timer = useRef<any | null>(null);
  const { t } = useTranslation();

  const DetailsValidation = Yup.object().shape({
    pinCode: Yup.string().required(
      t("registerPage.errors.invalidpincode") || "Pincode is required"
    ),
    code: Yup.string().required(
      t("registerPage.errors.invalidCode") ||
        "*That’s not a valid code. Please check and enter the correct code"
    ),
  });

  return (
    <MainLayout className="register-page">
      <Formik
        key="register-form"
        initialValues={{
          name: "",
          pinCode: "",
          code: "",
          // accessToken: "",
        }}
        validationSchema={DetailsValidation}
        onSubmit={(values, errors) => {
          console.log(values);
          // navigate(ROUTES.PAYOUT_FORM);

          API.register(values).then(() => {
            setShowOtpForm(true);
            navigate(ROUTES.PAYOUT_FORM);
          });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="register-form">
                <p className="form-title ">
                  Welcome To The <br />
                  ‘Design Your Creative Pack’
                  <br />
                  contest.
                </p>

                <div className="input-group">
                  <label className="input-label">Enter Name</label>
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.name")}
                  />
                </div>
                <div className="input-group">
                  <label className="input-label">Enter Pin-Code*</label>
                  <input
                    type="tel"
                    id="pinCode"
                    onChange={handleChange}
                    value={values.pinCode}
                    maxLength={6}
                    onBlur={handleBlur}
                    placeholder="Pincode"
                  />
                </div>
                <div className="input-group">
                  <label className="input-label">Enter Unique Code*</label>
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.code}
                    name="code"
                    maxLength={8}
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.code")}
                  />
                </div>

                {errors.pinCode && touched.pinCode && (
                  <p className="error">{errors.pinCode}</p>
                )}

                {!errors.pinCode && errors.code && touched.code && (
                  <p className="error">{errors.code}</p>
                )}

                <button className="btn btn-primary" type="submit">
                  {t("registerPage.submit")} <img src={arrow} alt="." />
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </MainLayout>
  );

  // return (
  //   <MainLayout className="register-page">{renderDetailsForm()}</MainLayout>
  // );
};

export default Details;
