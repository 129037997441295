import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../../layouts/MainLayout";
import "./Thankyou.scss";
import arrow from "../../../assets/images/arrow.png";
import { useEffect, useState } from "react";
import API from "../../../api";

import { ROUTES } from "../../../lib/consts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const Thankyou = (props: any) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const claimStatus = useSelector(
    (state: RootState) => state.authReducer.canClaim
  );
  const alreadyDesigned = useSelector((state: RootState) => {
    console.log(state);

    return state.authReducer.alreadyDesigned;
  });
  console.log(alreadyDesigned);
  // const {
  //   selectedColor,
  //   selectedElement,
  //   selectedStyle,
  //   selectedValue,
  //   setSelectedStyle,
  //   setSelectedValue,
  //   setSelectedElement,
  //   setSelectedColor,
  // } = props;

  // const handleColorOptionClick = (color: string) => {
  //   setSelectedColor(color);
  // };
  const designed = sessionStorage.getItem("alreadyDesigned");

  // const handleStyleButtonClick = (style: string) => {
  //   setSelectedStyle(style);
  //   console.log(`Selected Style: ${style}, Selected Color: ${selectedColor}`);
  // };
  // const handlevalueButtonClick = (value: string) => {
  //   setSelectedValue(value);
  //   console.log(`Selected Style: ${value}, Selected Color: ${selectedColor}`);
  // };
  // const handleElementButtonClick = (element: string) => {
  //   setSelectedElement(element);
  //   console.log(`Selected Style: ${element}, Selected Color: ${selectedColor}`);
  // };

  const handleClaimReward = () => {
    navigate(ROUTES.DESIGN_TEXT);
  };

  return (
    <>
      {/*<div className="thankyou-page-container">
        <p className="thankyou-page-title">Thank you for Your Participation</p>
        {claimStatus ? (
          <p className="thankyou-page-text">
            You have won an assured prize of <br />
            Rs. 20 Cashback. You will receive an SMS with the reward code and
            link within 24 hours.​
            <br /> <br />
            Also, you stand a chance to win exciting prizes!​
          </p>
        ) : (
          <p className="thankyou-page-text">
            Please keep participating to stand a chance to win exciting prizes
          </p>
        )}
        </div>*/}
      <div className="thankyou-page-container">
        <p className="thankyou-page-title">Thank you for participating!</p>
        {designed === "yes" ? (
          <p className="thankyou-page-text">
            Please keep participating to stand a chance to win exciting prizes
          </p>
        ) : (
          <p className="thankyou-page-text">
            Your cashback will be <br />
            processed in 24-48 business <br />
            hours
          </p>
        )}
      </div>
    </>
  );
};

export default Thankyou;
