import { useNavigate, useLocation } from "react-router-dom";
import React, { useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "./Style.scss";
import { ROUTES } from "../../../lib/consts";
import arrow from "../../../assets/images/arrow.png";
import API from "../../../api";
import { RED_COLOR, BLUE_COLOR } from "../Color/Color";
const Style = (props: any) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    selectedColor,
    selectedElement,
    selectedStyle,
    selectedValue,
    setSelectedStyle,
    setSelectedValue,
    setSelectedElement,
    setSelectedColor,
  } = props;

  const [error, setError] = useState("");

  const handleStyleButtonClick = (style: string) => {
    // API.saveStyleDetails(style);
    setError("");
    setSelectedStyle(style);
    console.log(`Selected Style: ${style}, Selected Color: ${selectedColor}`);
  };

  const handleClaimReward = () => {
    if (!selectedStyle) {
      setError("Please select a style.");
    } else {
      setError("");
      API.saveStyleDetails(selectedStyle);
      navigate(ROUTES.DESIGN_COLOR);
    }
  };

  return (
    <>
      <div className="style-section">
        <div className="section-title">
          <p className="section-sub" style={{ marginTop: "0" }}>
            Style
          </p>
          <p className="span">Choose the style that resonates with you!</p>
        </div>
        <div className="caraousel">
          <button
            className="style-button"
            style={{
              background:
                selectedStyle === "urban"
                  ? selectedColor === "warm"
                    ? RED_COLOR
                    : BLUE_COLOR
                  : "transparent",
              color:
                selectedStyle === "urban" && selectedColor !== null
                  ? "white"
                  : "#8c6629",
              border:
                selectedStyle === "urban" && selectedColor !== null
                  ? "transparent"
                  : "",
            }}
            onClick={() => handleStyleButtonClick("urban")}
          >
            Urban Centric
          </button>
          <button
            className="style-button"
            style={{
              background:
                selectedStyle === "classic"
                  ? selectedColor === "warm"
                    ? RED_COLOR
                    : BLUE_COLOR
                  : "transparent",
              color:
                selectedStyle === "classic" && selectedColor !== null
                  ? "white"
                  : "#8c6629",
              border:
                selectedStyle === "classic" && selectedColor !== null
                  ? "transparent"
                  : "",
            }}
            onClick={() => handleStyleButtonClick("classic")}
          >
            Classic Elegance
          </button>
          <button
            className="style-button"
            style={{
              background:
                selectedStyle === "futuristic"
                  ? selectedColor === "warm"
                    ? RED_COLOR
                    : BLUE_COLOR
                  : "transparent",
              color:
                selectedStyle === "futuristic" && selectedColor !== null
                  ? "white"
                  : "#8c6629",
              border:
                selectedStyle === "futuristic" && selectedColor !== null
                  ? "transparent"
                  : "",
            }}
            onClick={() => handleStyleButtonClick("futuristic")}
          >
            Futuristic Fusion
          </button>
        </div>
      </div>
      {error && <div className="error-mesg">{error}</div>}
      <div className="btn-container">
        <button className="open-text" onClick={handleClaimReward}>
          NEXT <img src={arrow} alt="." />
        </button>
      </div>
    </>
  );
};

export default Style;
