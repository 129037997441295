import {useTranslation} from "react-i18next";
import Popup from "../../helpers/Popup";

const HowTORedeemPopup = () => {
    const { t } = useTranslation();

    return (
        <Popup className="howtoredeem-us-popup">
            <p className="text1">HOW TO REDEEM</p>
            <ol>
                <li>User buys a pack and finds a paper inside which has a QR code and a missed call number
                </li>
                <li>User lands on the microsite –
                </li>
                <li>Gives a consent that they are a smoker? Yes/No
                </li>
                <li>Give a consent that they are above 18years of age? Yes/No
                </li>
                <li>Registers with Name, number, OTP and unique PI code
                </li>
                <li>Upload selfie and win reward within 48 hours if Navratri selfie is validated (Assured only for 1st time users.) All the entries will be judged on the creativity and involvement in the navratri moment.
                </li>
                <li>Rewards:
                    <ul>&#x2022; All valid entries registering with a valid unique code and valid navratri selfie shall stand a chance to will win an assured prize of a digital cashback of rs.20. the assured prize will be informed by sms within 48 hours of uploading the photo. Participants can keep participating to stand a chance to win exciting rewards
                    </ul>
                    <ul>&#x2022; Selected one best entry will participants also stand a chance to win a bike worth Rs. 1 lakh.
                    </ul>
                    <ul>&#x2022; The next 10 best entries will stand a chance to win smartphones e-vouchers worth rs.9999 and the next 4000 best entries will stand a chance to win digital cashback of Rs. 200 each
                    </ul>
                </li>
            </ol>
        </Popup>
    );
};

export default HowTORedeemPopup ;