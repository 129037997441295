import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import "./Design.scss";
import { ROUTES } from "../../lib/consts";
import { useEffect, useState } from "react";
import API from "../../api";
import pack from "../../assets/images/Packshot.png";
import urbanCentricImage from "../../assets/images/Urban-Centric-Warm.png";
import urbanCentricBlueImage from "../../assets/images/Urban-Centric-Cool.png";
import classicEleganceImage from "../../assets/images/Elegance.png";
import classicEleganceBlueImage from "../../assets/images/EleganceCool.png";
import futuristicFusionImage from "../../assets/images/Futuristic-Fusion-Warm.png";
import futuristicFusionBlueImage from "../../assets/images/Futuristic-Fusion-Cool.png";
import FashionForwardWarm from "../../assets/images/Fashion-Forward-Warm.png";
import FashionForwardWarmBlue from "../../assets/images/Fashion-Forward-Cool.png";
import YouthfulVibesWarm from "../../assets/images/Youthful-Vibes-Warm.png";
import YouthfulVibesWarmBlue from "../../assets/images/Youthful-Vibes-Cool.png";
import CulturallyConnectedWarm from "../../assets/images/Culturally-Connected-Warm.png";
import CulturallyConnectedBlue from "../../assets/images/Culturally-Connected-Cool.png";
import StrokesWarm from "../../assets/images/Strokes-Warm.png";
import StrokesBlue from "../../assets/images/Strokes-Cool.png";
import WavesWarm from "../../assets/images/Waves-Warm.png";
import WavesBlue from "../../assets/images/Waves-Cool.png";
import StarlightStreaksWarm from "../../assets/images/Starlight-Streaks-Warm.png";
import StarlightStreaksBlue from "../../assets/images/Starlight-Streaks-Cool.png";
import Color from "./Color/Color";
import { Route, Routes } from "react-router-dom";
import { Outlet } from "react-router-dom";

const Design = (props: any) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { selectedStyle, selectedValue, selectedElement, selectedColor, text } =
    props;

  const handleClaimReward = () => {
    navigate(ROUTES.DESIGN_TEXT);
  };

  const getImageForStyle = (style: string, isBlue: boolean) => {
    switch (style) {
      case "urban":
        return isBlue ? urbanCentricBlueImage : urbanCentricImage;
      case "classic":
        return isBlue ? classicEleganceBlueImage : classicEleganceImage;
      case "futuristic":
        return isBlue ? futuristicFusionBlueImage : futuristicFusionImage;
      default:
        return "";
    }
  };

  const getImageForValue = (style: string, isBlue: boolean) => {
    switch (style) {
      case "fashion":
        return isBlue ? FashionForwardWarmBlue : FashionForwardWarm;
      case "youth":
        return isBlue ? YouthfulVibesWarmBlue : YouthfulVibesWarm;
      case "culture":
        return isBlue ? CulturallyConnectedBlue : CulturallyConnectedWarm;

      default:
        return "";
    }
  };

  const getImageForElement = (style: string, isBlue: boolean) => {
    switch (style) {
      case "strokes":
        return isBlue ? StrokesBlue : StrokesWarm;
      case "waves":
        return isBlue ? WavesBlue : WavesWarm;
      case "starlight":
        return isBlue ? StarlightStreaksBlue : StarlightStreaksWarm;
      default:
        return "";
    }
  };

  return (
    <MainLayout className="upload-selfie-page">
      <div className="welcome-text" style={{ top: "1em" }}>
        <p>‘Design Your Creative Pack’</p>
      </div>
      <div className="pack-container">
        <div className="wrapper">
          <img className="pack" src={pack} alt="pack" />

          <div className="text1">{text}</div>
          <div className="text2">{text}</div>
          <div className="text3">{text}</div>

          {selectedStyle && (
            <img
              className="selected-style-image"
              src={getImageForStyle(selectedStyle, selectedColor === "cool")}
              alt={`Selected Style: ${selectedStyle}`}
            />
          )}
          {selectedValue && (
            <img
              className="selected-style-image"
              src={getImageForValue(selectedValue, selectedColor === "cool")}
              alt={`Selected Style: ${selectedStyle}`}
            />
          )}
          {selectedElement && (
            <img
              className="selected-style-image"
              src={getImageForElement(
                selectedElement,
                selectedColor === "cool"
              )}
              alt={`Selected Style: ${selectedStyle}`}
            />
          )}
        </div>
        <Outlet />
        {/*<div className="btn-container">
          <button className="open-text" onClick={handleClaimReward}>
            NEXT
          </button>
            </div>*/}
      </div>
    </MainLayout>
  );
};

export default Design;
